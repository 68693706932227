
















































































.gulations {
    .gulationsContent {
        margin-top: 0.3rem;

        .title1 {
            color: #ff1932;
            font-size: 0.3rem;
            margin: 0.4rem 0 0rem 0;
            font-weight: bold;
        }
		
        .title2,
        .title3,
        .title4,
        .title5 {
            font-size: 0.24rem;
            padding:.1rem .3rem .1rem 0;
            margin-bottom: 0.3rem;
			margin-top: 0.2rem;
        }
        .title2 {
            color: #ffffff;
            background: #000000;
		
        }

        .title3 {
            color: #ffffff;
            background: #ff1932;
        }

        .title4 {
            color: #ffffff;
            background: rgb(180,40,85);
        }

        .title5 {
            color: #000000;
            background: rgb(204,204,204);
        }

        .content {
            color: #333333;
            font-size: 0.24rem;
            line-height: 2;
            text-align: justify;
			margin-top: 0.2rem;
        }
    }
}


  @media (max-width: 767px) {
		.gulations {
		    .gulationsContent {
		        .title1 {
		          
		            font-size: 0.5rem;
		            margin: 0.4rem 0 0rem 0;
		           
		        }
		        .title2,
		        .title3,
		        .title4,
		        .title5 {
		            font-size: 0.46rem;
		            padding:.1rem .3rem .1rem 0;
		            margin-bottom: 0.3rem;
					margin-top: 0.6rem;
					
		        }
		        .title2 {
					margin-top: 0.2rem;	
		        }
		
		        .content {
		            font-size: 0.4rem;
					line-height: 1.8;
					margin-top: 0.4rem;
		           
		        }
		    }
		}   
        }
